<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <!-- 面包屑 -->
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/Examination/TestPaperManage"
            >试卷管理 /
          </router-link>
          <span class="crumbs_item crumbs_last">手动组卷</span>
        </span>
      </div>
    </div>
    <div class="sider_right">
      <a-select
        placeholder="难度"
        style="width: 150px"
        @change="selectDifficulty"
      >
        <a-select-option :value="null"> 全部 </a-select-option>
        <a-select-option value="1">简单</a-select-option>
        <a-select-option value="2">一般</a-select-option>
        <a-select-option value="3">困难</a-select-option>
      </a-select>
      <a-select
        placeholder="题目类型"
        style="width: 150px"
        @change="selectType"
      >
        <a-select-option :value="null"> 全部 </a-select-option>
        <a-select-option value="1">单选</a-select-option>
        <a-select-option value="2">多选</a-select-option>
        <a-select-option value="3">判断</a-select-option>
      </a-select>
      <a-input v-model="title" placeholder="请输入关键字" />
      <a-button type="primary" class="btn" @click="search"
        >搜索</a-button
      >
    </div>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item) => item.libraryId"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns"
        :data-source="tableData"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
      </a-table>
    </div>
    <div>
      <span>已选中共 {{ selectedRowKeys.length }} 道题目</span>
      <a-button type="primary" class="btn" @click="onpreview()"
        >试卷预览</a-button
      >
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "题干",
    dataIndex: "title",
  },
  {
    title: "章节",
    dataIndex: "sectionName",
  },
  {
    title: "难度",
    dataIndex: "difficulty",
  },
  {
    title: "类型",
    dataIndex: "type",
  },
];

const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    name: `题干类型是 ${i}`,
    age: `章节类型是 ${i}`,
    address: `难度类型是. ${i}`,
    class: `题目类型是. ${i}`,
  });
}

import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      data,
      columns,
      difficulty: "", // 	难度 1.简单 2.一般 3.困难
      title: "", // 	题干名称
      type: "", // 	类型 1.单选 2.多选 3.判断
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
    };
  },
  // 事件处理器
  methods: {
    onSelectChange(selectedRowKeys) {
      // console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getSubPaperList();
    },
    // 学习人数详情页
    goLearnPage() {
      this.$router.push({ path: "/admin/Certificate/Learner" });
    },
    // 获证人数详情页
    goObtainPage() {
      this.$router.push({ path: "/admin/Search/UserDetail" });
    },

    start() {
      this.loading = true;
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
    },
    onSelectChange(selectedRowKeys) {
      // console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    // 选择难度
    selectDifficulty(value) {
      this.difficulty = value;
    },
    // 选择类型
    selectType(value) {
      this.type = value;
    },

    // 试卷预览
    onpreview() {
      if (!this.selectedRowKeys.length) {
        return this.$message.warning("请先选择试题！");
      }
      let libraryIds = this.selectedRowKeys.join(",");
      this.$router.push({
        path:
          "/admin/Examination/preview?libraryIds=" +
          libraryIds +
          "&category=" +
          this.$route.query.category +
          "&courseId=" +
          this.$route.query.courseId +
          "&paperId=" +
          (this.$route.query.paperId?this.$route.query.paperId:'') +
          '&checkedValue='+(this.$route.query.checkedValue?this.$route.query.checkedValue:'') + // 多选分数
          '&redioValue='+(this.$route.query.redioValue?this.$route.query.redioValue:'') + // 单选分数
          '&switchValue='+(this.$route.query.switchValue?this.$route.query.switchValue:'') // 判断分数
      });
    },
     // 搜索
    search() {
      this.pageNumber = 1;
      this.getSubPaperList();
    },
    // 获取试题列表
    getSubPaperList() {
      this.$ajax({
        url: "/hxclass-management/exam/library/list",
        method: "get",
        params: {
          category: this.$route.query.category, // 大类
          courseId: this.$route.query.courseId, // 课程id
          current: this.pageNumber, // 当前页
          size: this.pageSize, //	总条数
          difficulty: this.difficulty,
          title: this.title,
          type: this.type,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    if(this.$route.query.libraryIds){
      let arr = this.$route.query.libraryIds.split(',');
      this.selectedRowKeys = arr.map(item=>{
        return item*1;
      })
    }
    // 获取试题列表
    this.getSubPaperList();
  },
  // 功能
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: "all-data",
            text: "Select All Data",
            onSelect: () => {
              this.selectedRowKeys = [...Array(46).keys()]; // 0...45
            },
          },
          {
            key: "odd",
            text: "Select Odd Row",
            onSelect: (changableRowKeys) => {
              let newSelectedRowKeys = [];
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return false;
                }
                return true;
              });
              this.selectedRowKeys = newSelectedRowKeys;
            },
          },
          {
            key: "even",
            text: "Select Even Row",
            onSelect: (changableRowKeys) => {
              let newSelectedRowKeys = [];
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return true;
                }
                return false;
              });
              this.selectedRowKeys = newSelectedRowKeys;
            },
          },
        ],
        onSelection: this.onSelection,
      };
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
.sider_right {
  display: flex;
  .ant-input {
    margin-left: 20px;
  }
  .ant-select {
    margin-right: 12px;
  }
  input {
    width: 350px;
    margin-right: 24px;
  }
}
</style>
