var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('div',{staticClass:"all_header_box"},[_c('div',{staticClass:"sider_left"},[_c('span',{staticClass:"title"},[_c('router-link',{staticClass:"crumbs_item",attrs:{"tag":"a","to":"/admin/Examination/TestPaperManage"}},[_vm._v("试卷管理 / ")]),_c('span',{staticClass:"crumbs_item crumbs_last"},[_vm._v("手动组卷")])],1)])]),_c('div',{staticClass:"sider_right"},[_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"难度"},on:{"change":_vm.selectDifficulty}},[_c('a-select-option',{attrs:{"value":null}},[_vm._v(" 全部 ")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("简单")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v("一般")]),_c('a-select-option',{attrs:{"value":"3"}},[_vm._v("困难")])],1),_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"题目类型"},on:{"change":_vm.selectType}},[_c('a-select-option',{attrs:{"value":null}},[_vm._v(" 全部 ")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("单选")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v("多选")]),_c('a-select-option',{attrs:{"value":"3"}},[_vm._v("判断")])],1),_c('a-input',{attrs:{"placeholder":"请输入关键字"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('a-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":_vm.search}},[_vm._v("搜索")])],1),_c('div',{staticClass:"table-box"},[_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item) { return item.libraryId; },"row-selection":{
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
      },"columns":_vm.columns,"data-source":_vm.tableData,"pagination":{
        total: _vm.total,
        current: _vm.pageNumber,
        defaultPageSize: _vm.pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return ("共" + total + "条");
        },
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.pageNumber - 1) * _vm.pageSize + i + 1)+" ")])]}}])})],1),_c('div',[_c('span',[_vm._v("已选中共 "+_vm._s(_vm.selectedRowKeys.length)+" 道题目")]),_c('a-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.onpreview()}}},[_vm._v("试卷预览")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }